<template lang="pug">
  .translation-list
    TopBar(
      @search="applySearch"
    )
    .translations-table-wrapper
      AppOverlayLoader(:state="loading && !$root.modalOpened")
      .translations-table
        VueGoodTable(
          :rows="translationsList"
          :columns="columns"
          :sort-options="{ enabled: false }"
        )
          template(v-slot:table-column="props")
            span(:title="props.column.label") {{ $t(props.column.label) }}
          template(v-slot:table-row="{ column, row, formattedRow }")
            .translation-key-field(v-if="column.field === 'key'")
              AppTextExpandable(
                :key="`translation-key-${row.id}`"
                :text="formattedRow.key"
              )
            .translation-value(v-else-if="column.field === 'en_value'")
              input.editable-field.form-control(
                :key="`translation-value-en-${row.id}`"
                :value="getValue(row.key, 'en')"
                :class="{ 'highlight-class': isEdited(row.key, 'en') }"
                @input="updateEnValue(row.key, $event.target.value)"
              )
              AppSaveButton.m-l-10(
                :key="`save-button-en-${row.id}`"
                short
                style="$default-purple"
                title="translations.table.columns.save"
                @save="saveEnChanges(row.key)"
              )
            .translation-value(v-if="column.field === 'ja_value'")
              input.editable-field.form-control(
                :key="`translation-value-ja-${row.id}`"
                :value="getValue(row.key, 'ja')"
                :class="{ 'highlight-class': isEdited(row.key, 'ja') }"
                @input="updateJaValue(row.key, $event.target.value)"
              )
              AppSaveButton.m-l-10(
                :key="`save-button-ja-${row.id}`"
                short
                title="translations.table.columns.save"
                @save="saveJaChanges(row.key)"
              )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        :auto-scroll-on-pagination="{ container: '.translations-table' }"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // store modules
  import translationsStore from "@/config/store/maestro/translations"

  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withConfirmation from "@/mixins/withConfirmation"
  import withStoreModule from "@/mixins/withStoreModule"

  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { columns } from "./TranslationsTableConfig"
  import "vue-good-table/dist/vue-good-table.css"
  import { isEmpty } from "lodash-es"

  const translationsMixin = withStoreModule(translationsStore, {
    resetState: true,
    name: "translations",
    readers: {
      translations: "items",
      translationsLoading: "loading",
      pagination: "pagination"
    },
    actions: {
      fetchTranslations: "FETCH_ITEMS",
      updateTranslation: "UPDATE_TRANSLATION",
      searchTranslation: "SEARCH_TRANSLATIONS",
      applySearch: "APPLY_SEARCH"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setFilters: "SET_FILTERS"
    }
  })

  export default {
    components: {
      VueGoodTable,
      TopBar: () => import("@/components/layout/TopBar"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable")
    },

    mixins: [withPermissions, withConfirmation, translationsMixin],

    data() {
      return {
        editedValues: {},
        inputValues: {}
      }
    },

    mounted() {
      isEmpty(this.translationsList) && this.fetchTranslations()
    },

    computed: {
      columns,

      loading({ translationsLoading }) {
        return translationsLoading
      },

      translationsList() {
        return this.translations
      }
    },

    methods: {
      isEdited(key, locale) {
        return Boolean(this.inputValues[key]?.[locale + "_value"])
      },

      getValue(key, locale) {
        const value = this.inputValues[key]?.[locale + "_value"]

        if (value) return value

        const row = this.translationsList.find(r => r.key === key)
        return row ? row[locale + "_value"] : ""
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchTranslations()
      },

      updateEnValue(key, value) {
        this.$set(this.inputValues, key, { ...this.inputValues[key], en_value: value })
        this.$set(this.editedValues, key, { ...this.editedValues[key], en_value: value })
      },

      updateJaValue(key, value) {
        this.$set(this.inputValues, key, { ...this.inputValues[key], ja_value: value })
        this.$set(this.editedValues, key, { ...this.editedValues[key], ja_value: value })
      },

      saveChanges(locale, key) {
        const value = this.editedValues[key]?.[`${locale}_value`]

        if (value) {
          this.updateTranslation({
            key,
            value,
            locale
          })

          const rowToUpdate = this.translationsList.find(r => r.key === key)

          if (rowToUpdate) {
            rowToUpdate[`${locale}_value`] = value
          }

          const hasInputValue = this.inputValues[key]

          if (hasInputValue) {
            this.$delete(hasInputValue, `${locale}_value`)
          }

          if (Object.keys(hasInputValue || {}).length === 0) {
            this.$delete(this.inputValues, key)
          }
        }
      },

      saveEnChanges(key) {
        this.saveChanges("en", key)
      },

      saveJaChanges(key) {
        this.saveChanges("ja", key)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/vue-good-table.sass"

  .app-overlay-loader
    z-index: 1

  .translations-table-wrapper
    position: relative

    ::v-deep
      .translations-table
        +listing-container(-35px)
        +app-vue-table

        .vgt-table
          border: none
          width: 100%

          .translation-key-field
            padding-top: 10px
            padding-bottom: 10px
            padding-right: 10px

          .translation-value
            display: flex
            align-items: center

          .editable-field
            margin-right: 10px
            font-size: 0.8rem

          .expandable-field
            max-width: 200px

          .vgt-right-align
            text-align: left

          th
            background: $th-background-color
            border-bottom: 1px solid $default-purple-light-line
            border-left: 1px solid $border-element-color
            border-right: 1px solid $border-element-color
            border-top: none
            color: $default-purple
            font-size: 0.8rem
            font-weight: 700
            padding: 17px 7px

            &:first-child
              border-left: 1px solid $default-purple-light-line

            &:last-child
              border-right: 1px solid $default-purple-light-line

          td
            border-bottom: 1px solid $default-purple-light-line
            border-left: 1px solid $border-element-color
            border-right: 1px solid $border-element-color
            border-top: 1px solid $default-purple-light-line
            color: $td-color
            font-size: 0.8rem
            padding: 11px 7px 13px
            max-width: 200px

            &:first-child
              border-left: 1px solid $default-purple-light-line

            &:last-child
              border-right: 1px solid $default-purple-light-line

            &.actions-field
              width: 1%
              min-width: 70px
              white-space: nowrap

            .table-actions
              text-align: center

              .app-icon
                fill: $default-purple
                height: 18px
                width: 18px

              .action-button
                +icon-button($default-purple)

                svg
                  margin: 0

        .highlight-class
          color: $default-purple
          background: $default-purple-light
</style>
